<template>
    <div class="wrapper-profile">
        <div class="v-form">
            <h2>Приз: {{ convertPrizeName(prizeName) }}</h2>
            <h4 style="text-align: left">
                Шаг 1. Заполнение документов. <br />
                Скачайте оба документа выше, распечатайте и подпишите
            </h4>

            <div class="contract-wrapper">
                <div class="contract-block">
                    <p>Договор дарения</p>
                    <v-icon x-large> mdi-file-pdf-box</v-icon>
                    <v-btn :loading="isLoadingContract" @click="getGeneratedContract"
                        >Скачать <v-icon right> mdi-download</v-icon>
                    </v-btn>
                </div>

                <div class="contract-block">
                    <p>Акт приемки-передачи</p>
                    <v-icon x-large> mdi-file-pdf-box</v-icon>

                    <v-btn
                        :loading="isLoadingTransferContract"
                        @click="getGeneratedWinnerTransferContract"
                        >Скачать <v-icon right> mdi-download</v-icon>
                    </v-btn>
                </div>
            </div>

            <h4 style="text-align: left">
                Шаг 2. Прикрепление документов. <br />
                Отсканируйте или сфотографируйте подписанные документы (на фотографии должно быть
                отчетливо видно содержимое документа) и загрузите их по кнопке ниже
            </h4>

            <v-form ref="form" @submit.prevent>
                <div class="upload-block">
                    <v-file-input
                        label="Договор дарения"
                        prepend-icon="mdi-cloud-upload"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        :rules="[rules.requireFileRules]"
                        @change="updateFiles"
                        @click:clear="clearFile"
                        outlined
                        dense
                    />
                    <v-file-input
                        label="Акт приемки-передачи"
                        prepend-icon="mdi-cloud-upload"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        :rules="[rules.requireFileRules]"
                        @change="updateFiles"
                        @click:clear="clearFile"
                        outlined
                        dense
                    />
                </div>

                <div class="checkbox-wrapper">
                    <div class="politic-checkbox">
                        <v-checkbox v-model="politicCheckbox" :rules="[rules.requirePolitic]" />

                        <p class="politic">
                            Я принимаю условия
                            <a
                                href="http://cdn.nahodka-magazin.ru/pdf/agreement.pdf"
                                target="_blank"
                                >пользовательского соглашения</a
                            >
                            и соглашаюсь с
                            <a href="http://cdn.nahodka-magazin.ru/pdf/policy.pdf" target="_blank"
                                >политикой обработки персональных данных</a
                            >
                        </p>
                    </div>

                    <div class="confirm-checkbox">
                        <v-checkbox
                            v-model="correctUserDataCheckBox"
                            :rules="[rules.requireCorrectUserData]"
                        />
                        <p class="confirm">
                            Я подтверждаю достоверность предоставленных мною данных
                        </p>
                    </div>
                </div>

                <v-btn :loading="isLoadingSendForm" @click="sendWinnerData">Отправить</v-btn>
                <p class="support-text">
                    Если данные в документах не соответствуют действительности или вы обнаружили
                    ошибки, свяжитесь с
                    <a :href="'mailto:support@nahodka-magazin.ru'" target="_blank">поддержкой</a>
                </p>
            </v-form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        rafflesId: { type: String, required: true },
        prizeName: { type: String, required: true },
    },
    data() {
        return {
            isLoadingContract: false,
            isLoadingTransferContract: false,
            isLoadingSendForm: false,
            files: [],
            politicCheckbox: false,
            correctUserDataCheckBox: false,
            rules: {
                requireFileRules: this.fileRules,
                requirePolitic: this.politicRules,
                requireCorrectUserData: this.userDataRules,
            },
        };
    },
    methods: {
        async getGeneratedContract() {
            try {
                this.isLoadingContract = true;

                const { data } = await axios.post(
                    `${process.env.VUE_APP_NT}/pdf-generator/winner-contract`,
                    {
                        userId: this.$store.state.userIdFromToken,
                        rafflesId: this.rafflesId,
                    },
                    { responseType: "blob" }
                );

                this.createDownloadLink(data, "Договор дарения");
            } catch (error) {
                console.log(error.message);
            } finally {
                this.isLoadingContract = false;
            }
        },

        async getGeneratedWinnerTransferContract() {
            try {
                this.isLoadingTransferContract = true;

                const { data } = await axios.post(
                    `${process.env.VUE_APP_NT}/pdf-generator/winner-transfer-contract`,
                    {
                        userId: this.$store.state.userIdFromToken,
                        rafflesId: this.rafflesId,
                    },
                    { responseType: "blob" }
                );

                this.createDownloadLink(data, "Акт приемки-передачи имущества");
            } catch (error) {
                console.log(error.message);
            } finally {
                this.isLoadingTransferContract = false;
            }
        },

        createDownloadLink(blobData, pdfName) {
            const blob = new Blob([blobData], { type: "application/pdf" });
            const link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = `${pdfName}.pdf`;
            link.click();

            window.URL.revokeObjectURL(link.href);
        },

        convertPrizeName(prizeName) {
            switch (prizeName) {
                case "blender":
                    return "Блендер";
                case "toaster":
                    return "Тостер";
                case "kettle":
                    return "Чайник";
                case "coffeeMaker":
                    return "Кофеварка";

                default:
                    return null;
            }
        },
        fileRules() {
            if (this.files.length < 2) return "Прикрепите договор дарения";
            return true;
        },

        politicRules() {
            if (!this.politicCheckbox) return false;
            return true;
        },
        userDataRules() {
            if (!this.correctUserDataCheckBox) return false;
            return true;
        },
        updateFiles(file) {
            if (!file) return;
            this.files.push(file);
            this.$refs.form.resetValidation();
        },
        clearFile() {
            this.files = [];
            this.$refs.form.resetValidation();
        },

        async sendWinnerData() {
            try {
                const formData = new FormData();

                const isValid = await this.$refs.form.validate();
                if (!isValid) return;

                formData.append("rafflesId", this.rafflesId);
                formData.append("userId", this.$store.state.userIdFromToken);
                this.files.forEach((file) => {
                    formData.append(`files`, file);
                });

                this.isLoadingSendForm = true;

                await axios.put(
                    `${process.env.VUE_APP_NT}/raffle/update-contract-files`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                this.$router.push({ name: "thankYou" }).catch(() => {});
            } catch (error) {
                alert("Ошибка отправки, попробуйте позже");
            } finally {
                this.isLoadingSendForm = false;
            }
        },
    },
};
</script>

<style scoped>
.table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.table-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    background-color: #f6f6f6;
    border-radius: 10px;
}

.table-line {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.field-title {
    display: flex;
    align-items: center;
    background-color: #dce4ec;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
}

.field-title-sended {
    display: flex;
    align-items: center;
    background-color: green;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
}

.field-value {
    overflow-x: auto;
    flex: 1;
    text-align: left;
}

.v-btn:not(.v-btn--round).v-size--default {
    margin-top: 0px;
}

.contract-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    align-items: center;
}

.contract-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 10px;
}
.upload-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.politic-checkbox,
.confirm-checkbox {
    display: flex;
    align-items: center;
    gap: 14px;
}

.politic,
.confirm,
.fileName {
    text-align: left;
    font-size: 14px;
    color: #868686;
}

.support-text {
    text-align: center;
    font-size: 13px;
    color: #868686;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .wrapper-profile {
        max-width: 800px;
        width: 100%;
    }

    .v-form {
        border-radius: 16px;
    }

    .contract-wrapper {
        flex-direction: row;
    }
}

@media screen and (max-width: 815px) {
    .contract-wrapper {
        flex-direction: column;
    }
}
</style>
